import React from 'react';
import { Flex } from '@instructure/ui-flex';

export const IconText = ({ icon, text }) => (
  <Flex>
    <Flex.Item>
      {icon}
    </Flex.Item>
    <Flex.Item margin="0 0 0 small" shouldGrow shouldShrink>
      {text}
    </Flex.Item>
  </Flex>
);
