import Uniflow from 'uniflow';
import * as LearningObjectsModel from '../models/resource';
import RouterActions from './router';
import ResultPathStore from '../stores/result-path';
import RouterStore from '../stores/router';
import SessionStore from '../stores/session';
import tracker from '../common/analytics/analytics';
import apiRequest from '../common/api-request';
import formatMessage from 'format-message';
import isForbidden from '../common/is-forbidden';
import isErroneous from '../common/is-erroneous';
import isUnauthorized from '../common/is-unauthorized';
import { localizeDescription } from '../models/thumbnail-get-description';
import { backgroundSessionRenewal } from './session';
import { redirectToLogin } from '../middleware/login-redirect';
import { resizeToThumbnail } from './util/resize-to-thumbnail';
import { dispatchUniflow } from './dispatch-uniflow';
import { addDialog, addLoginDialog } from './dialog';
import { addAlert } from './alert';
import CommonsAlert from '../components/common/alert';
import ResultActions from './results';

import env from '../config/env';

let findReq = null;

const isSessionFound = async () => backgroundSessionRenewal(env.SESSION_ID);

const transitionToShared = () => {
  RouterActions.transitionTo('/shared');
};

const transitionToResource = (isCreated, id) => {
  const params = isCreated ? { created: true } : null;
  RouterActions.transitionTo(`/resources/${id}`, params);
};

const transitionToAfter = (isCreated, id, newShareWorkflow) => {
  if (newShareWorkflow) {
    return transitionToShared();
  }
  return transitionToResource(isCreated, id);
};

export const ResourceActions = Uniflow.createActions({
  dispatch (action) {
    return dispatchUniflow(this, 'resource', action);
  },

  clearStore () {
    this.emit('learning-object-clear');
  },

  find (id) {
    this.emit('learning-object-find-pending');

    findReq = apiRequest({
      path: `/resources/${id}`,
      bodyTransform: localizeDescription
    });

    return new Promise(resolve => {
      findReq.end(async (err, res) => {
        if (isUnauthorized(err || res)) {
          if (env.SESSION_ID && !(await isSessionFound())) {
            resolve();
            return RouterActions.transitionTo('/errors/session-expired');
          } else {
            if (!redirectToLogin()) {
              return RouterActions.transitionTo('/search');
            }
            return resolve();
          }
        }
        if (isErroneous(err, res)) {
          return this.handleFindResponse(err, res ? res.body : null);
        }
        this.handleFindResponse(err, res.body);
        resolve();
      });
    });
  },

  getPlaceholderThumbnail () {
    const req = apiRequest({
      path: '/resources/placeholder-thumbnail'
    });

    return new Promise(resolve => {
      req.end(async (err, res) => {
        if (err || isUnauthorized(res) || isForbidden(res)) {
          return resolve(null);
        }

        const fields = {
          thumbnail: {
            url: res.body.url,
            description: formatMessage('Placeholder thumbnail')
          }
        };
        this.updateLocalLearningObject(fields);
        resolve(fields);
      });
    });
  },

  getReviews: async function getReviews (id) {
    this.emit('find-reviews-resource-pending');
    const req = apiRequest({
      path: `/resources/${id}/reviews`
    });
    const res = await req.promise();
    if (res && res.body.errors) {
      this.emit('find-reviews-resource-error', res.body.errors);
    }
    return this.emit('find-reviews-resource-success', res.body.items);
  },

  handleFindResponse (err, body) {
    if (isForbidden(err)) {
      return RouterActions.transitionTo('/errors/403');
    }

    if (err || body.errors) {
      return this.emit('learning-object-find-error', err || body.errors);
    }

    this.emit('learning-object-find-success', body);
  },

  clear () {
    this.emit('learning-object-clear');
  },

  abortFind () {
    if (findReq) {
      findReq.abort();
      findReq = null;
      this.emit('learning-object-find-aborted');
    }
  },

  uploadThumbnail: async function uploadThumbnail (learningObject, prefix, isIgnorePensieveThumbnailEnabled) {
    const handleError = () => {
      this.emit(`${prefix}-error`, [
        {
          param: 'learning-object',
          msg: formatMessage('Failed to upload thumbnail. Please try again.')
        }
      ]);
    };

    if (!(learningObject.thumbnailFile instanceof window.Blob)) {
      if (!isIgnorePensieveThumbnailEnabled || learningObject.type !== 'image') {
        return learningObject;
      }

      try {
        const imageBlob = await this.downloadCanvasImage(learningObject.thumbnail.url);
        const thumbnailBlob = await resizeToThumbnail(imageBlob);
        learningObject.thumbnailFile = thumbnailBlob;
      } catch (_) {
        handleError();
        return;
      }
    }

    try {
      const body = new FormData();
      body.append(
        'thumbnail',
        learningObject.thumbnailFile,
        learningObject.thumbnailFile.name
      );
      const req = apiRequest({
        method: 'post',
        path: '/resources/thumbnail',
        contentType: null,
        body
      });
      const res = await req.promise();
      if (res && res.body.errors) {
        this.emit(`${prefix}-error`, res.body.errors);
        return;
      }
      learningObject.thumbnail.url = res.body.url;
      learningObject.thumbnailFile = undefined;
      this.updateLocalLearningObject(learningObject);
      return learningObject;
    } catch (_) {
      handleError();
    }
  },

  downloadCanvasImage: async function downloadCanvasImage (url) {
    if (!url) {
      throw new Error('No thumbnail image URL provided');
    }

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Failed to download thumbnail image');
    }

    return response.blob();
  },

  updateExisting: async function updateExisting (data) {
    this.updateLocalLearningObject(data);
    this.emit('learning-object-update-pending');
    data = await this.uploadThumbnail(data, 'learning-object-update');
    if (!data) return;
    LearningObjectsModel.update(
      data,
      this.handleUpdateResponse.bind(this, 'learning-object-update', false, false)
    );
  },

  updateWithNewVersion: async function updateWithNewVersion (
    data,
    isCurator,
    allowApprovedContent = false,
    newShareWorkflow = false
  ) {
    if (
      !data.id ||
      (!isCurator &&
        data.scopeIds.some(scopeId => scopeId.includes('curated-')) &&
        allowApprovedContent)
    ) {
      return this.emit('learning-object-update-new-version-error', [
        {
          param: 'resourceId',
          msg: 'valid resource is required'
        }
      ]);
    }

    this.updateLocalLearningObject(data);
    this.emit('learning-object-update-new-version-pending');
    data = await this.uploadThumbnail(
      data,
      'learning-object-update-new-version'
    );
    if (!data) return;
    LearningObjectsModel.updateWithNewVersion(
      data,
      this.handleUpdateResponse.bind(
        this,
        'learning-object-update-new-version',
        true,
        newShareWorkflow
      )
    );
  },

  handleUpdateResponse (prefix, isCreated, transferToSharePage, err, res) {
    if (err && err.status === 400) {
      return this.emit(`${prefix}-error`, err.errors);
    }
    if (err) {
      tracker.logAction('Share', 'ErrorsOnExport');
      return this.emit(`${prefix}-error`, [
        {
          param: 'learning-object',
          msg: formatMessage(
            'Failed to update learning resource. Please try again.'
          )
        }
      ]);
    }

    this.emit(`${prefix}-success`, res.model);
    this.clearStore();
    this.find(res.model.id);

    transitionToAfter(isCreated, res.model.id, transferToSharePage);
  },

  createNew: async function createNew (data, newShareWorkflow, isIgnorePensieveThumbnailEnabled) {
    this.updateLocalLearningObject(data);
    this.emit('learning-object-create-pending');
    data = await this.uploadThumbnail(data, 'learning-object-create', isIgnorePensieveThumbnailEnabled);
    if (!data) return;
    LearningObjectsModel.create(data, this.handleCreateResponse(newShareWorkflow));
  },

  handleCreateResponse (newShareWorkflow) {
    return (err, res) => {
      if (err && err.status === 400) {
        tracker.logAction('Share', 'ErrorsOnExport');
        return this.emit('learning-object-create-error', err.errors);
      }
      if (err || !res || !res.model) {
        tracker.logAction('Share', 'ErrorsOnExport');
        return this.emit('learning-object-create-error', [
          {
            param: 'learning-object',
            msg: formatMessage(
              'Failed to create learning resource. Please try again.'
            )
          }
        ]);
      }

      tracker.logAction('Share', 'SuccessfulExport');
      this.emit('learning-object-create-success', res.model);
      this.clearStore();
      this.find(res.model.id);
      transitionToAfter(true, res.model.id, newShareWorkflow);
    };
  },

  updateLocalLearningObject (fields) {
    if (SessionStore.state.publicOnly) {
      fields.scopeIds = ['PUBLIC'];
    }
    this.emit('learning-object-update', fields);
  },

  destroyById (learningObjectId) {
    LearningObjectsModel.destroy(learningObjectId, (err, res) => {
      if (err) return;
      if (res.status !== 204) return;

      this.emit('learning-object-destroyed-refetch', learningObjectId);
      this.emit('learning-object-destroyed', learningObjectId);
      // only perform a transitionTo if we aren't already on a results page
      // aka the delete was initiated from the trash icon on a results page
      if (RouterStore.state.pathname !== ResultPathStore.state.pathname) {
        RouterActions.transitionTo(
          `${ResultPathStore.state.pathname}${ResultPathStore.state.search}`
        );
      }
    });
  },

  importIntoCourse ({ learningObjectId, courseId, courseName, target }, done) {
    var courses = [{ id: courseId, name: courseName }];
    this.emit('learning-object-import-pending');
    LearningObjectsModel.importIntoCourse(
      {
        learningObjectId,
        courses,
        target
      },
      (err, res) => {
        if (err) {
          if (done) {
            done({ error: err });
          }
          return this.emit(
            'learning-object-import-result',
            courses.map(course => {
              course.status = 'failed';
              course.msg = err.message;
              return course;
            })
          );
        }
        this.emit('learning-object-import-result', res.body);
        if (done) {
          done(res.body[0]);
        }
      }
    );
  },

  importIntoMultiCourses (learningObjectId, courses) {
    if (!Array.isArray(courses) || courses.length === 0) {
      return;
    }
    this.emit('learning-object-import-pending');
    LearningObjectsModel.importIntoCourse(
      {
        learningObjectId,
        courses
      },
      (err, res) => {
        if (err) {
          return this.emit(
            'learning-object-import-result',
            courses.map(course => {
              course.status = 'failed';
              course.msg = err.errors[0].msg;
              course.learningObjectId = learningObjectId;
              return course;
            })
          );
        }
        this.emit('learning-object-import-result', res.body);
      }
    );
  },

  copyLinkToClipboard ({
    payload
  }) {
    const resource = new LearningObjectsModel.Resource(payload.resource);
    const link = resource.shareLink;
    const textareaRef = payload.textareaRef;
    const successAlert = {
      id: `${resource.id}-copy-link`,
      type: CommonsAlert.type.success,
      message: formatMessage('Resource link copied to clipboard.')
    };
    const failAlert = {
      id: `${resource.id}-copy-link-fail`,
      type: CommonsAlert.type.error,
      message: formatMessage('Failed to copy link to clipboard.')
    };

    if (navigator.clipboard) {
      navigator.clipboard.writeText(link).then(() => {
        addAlert(successAlert);
      }).catch(() => {
        addAlert(failAlert);
      });
    } else if (textareaRef) {
      textareaRef.select();
      document.execCommand('copy');
      addAlert(successAlert);
    } else {
      addAlert(failAlert);
    }
  },

  editScreen ({
    payload
  }) {
    RouterActions.transitionTo(
      `/resources/${payload.resource.id}/edit`
    );
  },

  downloadDo ({
    payload
  }) {
    const resource = payload.resource;

    ResultActions.incrementDownloadCount(resource.id);
    this.emit('learning-object-increment-download-count', resource.id);

    const url = LearningObjectsModel.getDownloadUrl(resource.id);

    const link = document.createElement('a');
    if (typeof link.download === 'string') {
      link.href = url;
      link.setAttribute('download', '');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(url);
    }
  },

  downloadPrep ({
    payload
  }) {
    if (!SessionStore.state.isAuthenticated) {
      addLoginDialog();
      return;
    }

    const resource = payload.resource;
    const resourceModel = new LearningObjectsModel.Resource(resource);

    let message;
    if (resource.versions.length >= 1 && resource.versions[0].resourceType && resource.versions[0].size) {
      message = formatMessage(
        'Your download is a {type} and its size is {size}.',
        {
          size: resourceModel.latestVersionSizeI18n,
          type: resourceModel.latestVersionTypeI18n
        }
      );
    } else {
      message = formatMessage(
        'Your download size might be large, especially if you are downloading a video or a complete course.'
      );
    }

    addDialog({
      id: `download_${resource.id}`,
      title: formatMessage('Download resource'),
      message,
      onConfirm: { type: 'resource/downloadDo', payload: { resource } },
      confirmText: formatMessage('Download')
    });
  },

  deleteDo ({
    payload
  }) {
    ResourceActions.destroyById(payload.resource.id);
  },

  deletePrep ({
    payload
  }) {
    if (!SessionStore.state.isAuthenticated) {
      addLoginDialog();
      return;
    }

    const resource = payload.resource;
    const message = formatMessage('You will permanently remove {name} from Commons.', {
      name: resource.title
    });
    addDialog({
      id: `delete_${resource.id}`,
      title: formatMessage('Remove {name}?', { name: resource.title }),
      message,
      onConfirm: { type: 'resource/deleteDo', payload: { resource } },
      confirmText: formatMessage('Remove')
    });
  }
});

export default ResourceActions;
