import React from 'react';
import PropTypes from 'prop-types';
import { IconTrashLine, IconEditLine } from '@instructure/ui-icons';
import formatMessage from 'format-message';

import Link from '../common/link';
import { dispatch } from '../../store';

class LearningObjectToolbar extends React.Component {
  static propTypes = {
    learningObject: PropTypes.object.isRequired
  }

  render () {
    return (
      <div className="Toolbar">
        <Link
          className="lor-edit-button"
          renderIcon={IconEditLine}
          onClick={() => {
            dispatch({
              type: 'resource/editScreen',
              payload: {
                resource: this.props.learningObject
              }
            });
          }}
        >
          {formatMessage('Edit Resource')}
        </Link>
        <Link
          className="lor-delete-button"
          renderIcon={IconTrashLine}
          onClick={() => {
            dispatch({
              type: 'resource/deletePrep',
              payload: {
                resource: this.props.learningObject
              }
            });
          }}
        >
          {formatMessage('Remove from Commons')}
        </Link>
      </div>
    );
  }
}

export default LearningObjectToolbar;
