import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import formatMessage from 'format-message';
import { Table } from '@instructure/ui-table';
import { Flex } from '@instructure/ui-flex';
import { Text } from '@instructure/ui-text';

import { resourceTypes } from '../../../common/app-constants';
import Link from '../../common/link';
import Pill from '../../common/pill';
import ResourceTypeIcon from '../../icons/resource-type';
import { mapToGradeAbbreviation, sortGrades } from '../../resources/gradeText';
import { TruncateWithTooltip } from '../../common/truncate-with-tooltip';
import { withCuratedBanner } from '../../common/with-curated-banner';
import { getTextAlignByView } from './list-headers';
import FavoriteCell from './favorite-cell';
import * as actions from './actions';

const convertToSortedGradeLabels = (gradeIds) => {
  return gradeIds.sort(sortGrades).map(mapToGradeAbbreviation).join(', ');
};

const ResourceType = ({ resourceType, isDesktop }) => {
  return (
    <React.Fragment>
      <ResourceTypeIcon
        className="resource-type-icon--outline"
        type={resourceType}
        colored
        ghost
        raw />
      {!isDesktop && (<div className={`font-color type-${resourceType}`}>
        <Text size="x-small" weight="bold" transform="uppercase">
          {resourceTypes[resourceType].singularName}
        </Text>
      </div>)}
    </React.Fragment>
  );
};

// group the featured pill and curated banner icon together for stacked view
const FeaturedPillWithCuratedBanner = ({ isFeatured, resourceType, curatedBanner }) => {
  return (
    <Flex
      gap="small"
      alignItems="center"
    >
      {isFeatured && (
        <Pill
          className={`type-${resourceType} background-color`}
          themeOverride={{ primaryColor: 'white', padding: '2px 8px' }}
        >
          {formatMessage('Featured')}
        </Pill>
      )}
      {curatedBanner && (
        <div
          style={{
            backgroundImage: `url(${curatedBanner.image})`,
            backgroundColor: curatedBanner.color || 'var(--color-magenta)',
          }}
          className="curated-banner-icon"
        />
      )}
    </Flex>
  );
};

const ResourceTitleCell = ({ resource, isDesktop, curatedBanner }) => {
  return (
    <div
      className={classnames('resource-title-cell', { 'resource-title-cell--stacked': !isDesktop })}
    >
      <Flex gap="small">
        <ResourceType
          resourceType={resource.type}
          isDesktop={isDesktop}
        />
      </Flex>
      <Link
        href={`/resources/${resource.id}`}
        className="resource-title-link"
      >
        <TruncateWithTooltip text={resource.title} />
      </Link>
      {(resource.featured || curatedBanner) && (
        <FeaturedPillWithCuratedBanner
          isFeatured={resource.featured}
          resourceType={resource.type}
          curatedBanner={curatedBanner}
        />)}
    </div>
  );
};

const GradeLevelsCell = ({ resource }) => {
  return !!resource.gradeIds && <TruncateWithTooltip text={convertToSortedGradeLabels(resource.gradeIds)} />;
};

const AuthorCell = ({ resource }) => {
  const author = resource.authors && resource.authors[0];

  if (!author || !author.name) {
    return null;
  }

  return (
    <React.Fragment>
      {/* TODO: display avatar when we have the unified avatar url */}
      {/* <Avatar
        data-heap-redact-attributes="url"
        url={author.avatarUrl}
      /> */}
      <TruncateWithTooltip
        text={author.name}
        className="author-name"
      />
    </React.Fragment>
  );
};

const ListRow = (props) => {
  const { resource, isFavorite, isDesktop, curatedBanner, session, featureFlags, ...rest } = props;
  const textAlign = getTextAlignByView(isDesktop);
  const resourceUrlTextArea = useRef(null);
  const actionables = actions.availableActions(resource);

  return (
    <Table.Row
      {...rest}
      data-display={resource.featured ? 'featured' : 'default'}
      data-type={resource.type}
    >
      <Table.Cell>
        <ResourceTitleCell
          resource={resource}
          isDesktop={isDesktop}
          curatedBanner={curatedBanner}
        />
      </Table.Cell>
      <Table.Cell>
        <GradeLevelsCell resource={resource} />
      </Table.Cell>
      <Table.Cell>
        <AuthorCell resource={resource} />
      </Table.Cell>
      <Table.Cell textAlign={textAlign}>
        <span>{formatMessage('{date, date}', { date: new Date(resource.updateDate) })}</span>
      </Table.Cell>
      <Table.Cell textAlign={textAlign}>
        <span>{resource.favoritedCount}</span>
      </Table.Cell>
      <Table.Cell textAlign={textAlign}>
        <span>{resource.downloadCount}</span>
      </Table.Cell>
      <Table.Cell textAlign={textAlign}>
        <FavoriteCell
          resource={resource}
          isFavorite={isFavorite}
          isDesktop={isDesktop}
          session={session}
        />
      </Table.Cell>
      {featureFlags.flags.list_view_actions && isDesktop && actions.actionMenu({
        resource,
        actions: actionables,
        textAlign,
        refBack: resourceUrlTextArea,
      })}
      {featureFlags.flags.list_view_actions && !isDesktop && actionables.map(
        ({ label, icon, action }) => actions.actionItemAsLink({
          resource,
          textAlign,
          refBack: resourceUrlTextArea,
          label,
          icon,
          action,
        })
      )}
    </Table.Row>
  );
};

ListRow.propTypes = {
  resource: PropTypes.object.isRequired,
  isFavorite: PropTypes.bool,
  isDesktop: PropTypes.bool.isRequired,
  allowApprovedContent: PropTypes.bool,
  accountSettings: PropTypes.object,
  accountId: PropTypes.string,
  curatedBanner: PropTypes.object,
  featureFlags: PropTypes.object,
};

ListRow.displayName = 'Row';

export default withCuratedBanner(ListRow);
