import React, { useEffect } from 'react';
import { getState, dispatch } from '../store';
import ConfirmationDialog from './confirmation-dialog';
import { removeDialog, removeAllDialogs } from '../actions/dialog';

export default function DialogHandler () {
  const dialog = getState().dialog;

  useEffect(() => {
    return () => removeAllDialogs();
  }, []);

  if (!dialog.currentDialogs || dialog.currentDialogs.length === 0) {
    return null;
  }

  const dialogData = dialog.currentDialogs[0];

  const onClose = (id, result) => {
    const callback = result ? dialogData.onConfirm : dialogData.onCancel;
    if (typeof callback === 'function') {
      callback();
    } else if (callback) {
      dispatch(callback);
    }
    removeDialog(id);
  };

  return (
    <div data-testid="dialog-handler" className="lor-dialoghandler">
      <ConfirmationDialog
        label={dialogData.label}
        title={dialogData.title}
        message={dialogData.message}
        confirmText={dialogData.confirmText}
        cancelText={dialogData.cancelText}
        hideCancelButton={!dialogData.withCancel}
        onClose={(result) => onClose(dialogData.id, result)} />
    </div>
  );
}
