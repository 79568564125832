import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { IconDownloadLine } from '@instructure/ui-icons';

import Button from '../common/button';
import { dispatch } from '../../store';

export default class ResourceDownloadLink extends React.Component {
  static propTypes = {
    learningObject: PropTypes.object.isRequired
  }

  render () {
    const { learningObject } = this.props;

    return (
      <div className="lor-course-download">
        <Button
          renderIcon={IconDownloadLine}
          display={Button.display.block}
          textAlign={Button.textAlign.start}
          onClick={() => {
            dispatch({
              type: 'resource/downloadPrep',
              payload: {
                resource: learningObject
              }
            });
          }}
        >
          {formatMessage('Download')}
        </Button>
      </div>
    );
  }
}
