import Uniflow from 'uniflow';
import apiRequest from '../common/api-request';
import { isResourcePaginated } from '../common/util/is-resource-paginated';
import localizeErrors from '../models/error-messages/resources';
import RouterActions from '../actions/router';
import ResultsActions from '../actions/results';
import LearningObjectActions from '../actions/resource';
import RouterStore from '../stores/router';
import ResultsStore from '../stores/results';
import buildSearchQuery from './util/build-search-query';

// models
import * as LearningObjectModel from '../models/resource';
import { localizeDescription } from '../models/thumbnail-get-description';
import { calculatePaginationPages } from '../common/util/calculate-pagination-pages';

let bootstrapped = false;

const previousSearchState = {
  params: {},
  path: '',
  options: {}
};

const SearchActions = Uniflow.createActions({
  bootstrap () {
    if (bootstrapped) {
      return;
    }

    LearningObjectActions.on(
      'learning-object-destroyed-refetch',
      SearchActions.refetchPage
    );
    ResultsActions.on(
      'remove-from-favorites-refetch',
      () => SearchActions.refetchPage()
    );

    bootstrapped = true;
  },

  search (query) {
    query.where.cursor = query.where.cursor || 'initial';
    this.emit('search-pending', query);
    LearningObjectModel.findAll(query, this.handleSearchResponse);
  },

  update (change) {
    const params = Object.assign({}, RouterStore.state.query, change);

    for (let key of Object.keys(params)) {
      if (key === 'query') {
        key = 'q';
        params.q = params.query;
        delete params.query;
      }
      if (Array.isArray(params[key])) {
        params[key] = params[key].join(',');
      }
      if (params[key] == null || params[key] === '') {
        delete params[key];
      }
    }

    // reset page count if the filters or the ordering have changed
    if (params.page && !change.page) {
      delete params.page;
    }

    RouterActions.replaceWith(RouterStore.state.pathname, params);
  },

  clear () {
    const params = {
      ...RouterStore.state.query,
    };

    for (const key of Object.keys(params)) {
      if (key === 'q' || key === 'sortBy') {
        continue;
      }

      delete params[key];
    }

    RouterActions.replaceWith(RouterStore.state.pathname, params);
  },

  handleSearchResponse (err, results) {
    if (err) {
      this.emit('search-error', err);
    } else {
      this.emit('search-successful', results);
    }
  },

  loadNextPage (cursor, lastQuery) {
    if (cursor) {
      var query = Object.assign({}, lastQuery);
      query.where.cursor = cursor;

      this.search(query);
    }
  },

  clearResults () {
    this.emit('results-reset');
  },

  redirectToLastPage (count, size) {
    const paginationPages = calculatePaginationPages(1, count, size);

    return this.update({
      page: paginationPages.lastAvailablePage,
    });
  },

  fetchResultsWithPath (params, path, options = {}) {
    previousSearchState.params = params;
    previousSearchState.path = path;
    previousSearchState.options = options;

    const query = buildSearchQuery(params, options);

    if (query.cursor === undefined) {
      this.emit('results-reset');
    }
    this.emit('results-reset-placeholder');

    const deletePreviousItems = !!options.isPaginated;

    const req = apiRequest({
      path,
      query
    });
    this.emit('results-requested', req, deletePreviousItems);

    req.end((err, res) => {
      if (err) {
        localizeErrors(err);
        return this.emit('results-error', err);
      }

      const isPageInvalid = res.body.meta &&
        res.body.meta.count &&
        res.body.items &&
        !res.body.items.length;

      if (options.isPaginated && isPageInvalid) {
        return this.redirectToLastPage(res.body.meta.count, query.size);
      }

      (res.body.items || []).forEach(localizeDescription);
      this.emit('results-response', req, res, deletePreviousItems);
    });
  },

  refetchPage () {
    const isPageEmptyAfterRemoval = ResultsStore.get().length === 1;
    if (!isResourcePaginated() || (!ResultsStore.hasMore() && !isPageEmptyAfterRemoval) || !previousSearchState.path) {
      return;
    }

    this.emit('results-increase-placeholder');

    const prevQuery = buildSearchQuery(previousSearchState.params, previousSearchState.options);
    const req = apiRequest({
      path: previousSearchState.path,
      query: {
        ...prevQuery,
        size: prevQuery.size + 1 // increase the size to prevent too fast refetching bug (we got the deleted item too)
      }
    });

    req.end((err, res) => {
      if (ResultsStore.getPlaceholdersCount() === 0) {
        return;
      }
      this.emit('results-decrease-placeholder');

      if (err) {
        return;
      }

      (res.body.items || []).forEach(localizeDescription);
      this.emit('results-sync-response', res);

      const isPageEmpty = ResultsStore.get().length === 0;
      const queryPage = parseInt(previousSearchState.params.page) || 1;
      if (isPageEmpty && queryPage > 1) {
        this.update({ page: queryPage - 1 });
      }
    });
  }
});

export default SearchActions;
