import Uniflow from 'uniflow';

export default Uniflow.createActions({
  removeFromFavorites (learningObjectId) {
    this.emit('remove-from-favorites-refetch', learningObjectId);
    this.emit('remove-from-favorites', learningObjectId);
  },

  incrementDownloadCount (learningObjectId) {
    this.emit('increase-download-count', learningObjectId);
  }
});
