import React from 'react';
import PropTypes from 'prop-types';
import formatMessage from 'format-message';
import { IconLinkLine } from '@instructure/ui-icons';

import { Resource } from '../../models/resource';
import { dispatch } from '../../store';
import Button from '../common/button';

export default class CopyResourceLinkButton extends React.Component {
  static propTypes = {
    learningObject: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props);
    const resourceModel = new Resource(this.props.learningObject);
    this.shareLink = resourceModel.shareLink;
  }

  onClick = event => {
    dispatch({
      type: 'resource/copyLinkToClipboard',
      payload: {
        resource: this.props.learningObject,
        textareaRef: this.resourceUrlTextArea
      }
    });
  }

  render () {
    return (
      <div className="lor-copy-resource-link-btn">
        <Button
          renderIcon={IconLinkLine}
          onClick={this.onClick}
          display={Button.display.block}
        >
          {formatMessage('Copy Resource Link')}
        </Button>
        <textarea
          className="lor-copy-resource-link-btn-link"
          ref={(textarea) => { this.resourceUrlTextArea = textarea; }}
          value={this.shareLink}
          readOnly
          aria-hidden="true"
          tabIndex="-1"
        />
      </div>
    );
  }
}
